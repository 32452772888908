/*
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-10-26 19:54:29
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-11-23 17:22:20
 * @FilePath: /dapp_vue/src/components/Code/Code.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import tip from './tipView.vue'
import { createApp } from "vue"

let tipDom;
const tip_view = (text) => {
    return new Promise((res, rej) => {
        tipDom = createApp(tip).mount(document.createElement("div"));
        tipDom.text = Object.assign({}, tipDom.text, text);;
        document.body.appendChild(tipDom.$el);
        tipDom.confirm = function () {
            tipDom.isShow = false;
        }
        tipDom.cancel = function () {
            tipDom.isShow = false;
        }
    })
}
export default tip_view
