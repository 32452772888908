<template>
  <div class="collaspe">
    <a href="javascript:;" class="menu-icon" @click="handleOpen">
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
          fill="#fff"
          d="M4 18H20C20.55 18 21 17.55 21 17C21 16.45 20.55 16 20 16H4C3.45 16 3 16.45 3 17C3 17.55 3.45 18 4 18ZM4 13H20C20.55 13 21 12.55 21 12C21 11.45 20.55 11 20 11H4C3.45 11 3 11.45 3 12C3 12.55 3.45 13 4 13ZM3 7C3 7.55 3.45 8 4 8H20C20.55 8 21 7.55 21 7C21 6.45 20.55 6 20 6H4C3.45 6 3 6.45 3 7Z"
        />
      </svg>
    </a>
    <teleport to="body">
      <div
        class="common-aside-widget common-aside-right"
        :class="animateShow ? 'animate-show' : ''"
        v-if="visible"
      >
        <div class="content-view" style="width: 50%">
          <a class="button-close">
            <span
              href="javascript:;"
              class="common-close"
              @click="handleClose"
            ></span>
          </a>

          <div class="menus">
            <div class="menu-item">
              <router-link
                to="/"
                class="router-link-exact-active router-link-active"
                >{{ localeData["navbar.home"] }}</router-link
              >
            </div>

            <div class="menu-item">
              <a href="https://dapp.zeptosfintech.io/buy" target="_blank">
                {{ localeData["navbar.market"] }}
              </a>
            </div>

            <div class="menu-item">
              <a href="https://game.zeptosfintech.io/" target="_blank">
                {{ localeData["navbar.game"] }}
                <span class="item-arrow item-arrow-hot"></span>
              </a>
            </div>

            <div class="menu-item">
              <a href="javascript:;">{{ localeData["navbar.rabbit"] }}</a>
            </div>

            <div class="menu-item">
              <a href="javascript:;">{{ localeData["navbar.bunny"] }}</a>
            </div>
            <div class="menu-item">
              <a href="javascript:;">{{ localeData["navbar.team"] }}</a>
            </div>
            <div class="menu-item">
              <a href="javascript:;">{{ localeData["navbar.roadMap"] }}</a>
            </div>
            <div class="menu-item">
              <router-link
                to="/docs"
                class="router-link-exact-active router-link-active"
                >{{ localeData["navbar.docs"] }}</router-link
              >
            </div>
            <div class="menu-item">
              <a href="javascript:;">{{ localeData["navbar.about"] }}</a>
            </div>
          </div>
        </div>
      </div>
    </teleport>
  </div>
</template>

<script setup>
import { ref } from "vue";
import useLocale from "../hooks/useLocale";
const localeData = useLocale();

const visible = ref(false);
const animateShow = ref(false);

const handleOpen = () => {
  visible.value = true;
  setTimeout(() => {
    animateShow.value = true;
  }, 200);
};

const handleClose = () => {
  animateShow.value = false;
  setTimeout(() => {
    visible.value = false;
  }, 200);
};

const clickBtn = () => {



};
</script>

<style lang="less" scoped>
.collaspe {
  display: none;
}
@media screen and (max-width: 960px) {
  .collaspe {
    display: block;
  }
}
.menu-icon {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}
.menu-icon svg {
  width: 68%;
}
.common-aside-widget.animate-show {
  background-color: rgba(0, 0, 0, 0.6);
}
.common-aside-widget {
  position: fixed;
  z-index: 128;
  background-color: transparent;
  top: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  transition: background-color 0.4s ease;
}
.common-aside-widget.animate-show .content-view {
  transform: translateX(0);
}
.common-aside-widget .content-view {
  height: 100%;
  width: 78%;
  right: 0;
  background: rgba(0, 0, 0, 0.78);
  position: absolute;
  transition: transform 0.3s ease;
  transform: translateX(100%);
}
.button-close {
  position: absolute;
  top: 0;
  right: 0;
  width: 68px;
  height: 68px;
}
.menus {
  padding: 86px 20px 20px 52px;
}
.menus .menu-item {
  padding: 16px 0;
  color: white;
}
.menus a {
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  transition: color 0.4s ease;
}
.menus a .item-arrow {
  width: 18px;
  height: 18px;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  display: block;
  margin-left: 8px;
}
.menus a .item-arrow-new {
  width: 40px;
  height: 18px;
  // background-image: url(../assets/ball.png);
}
.menus a .item-joindiscord {
  width: 40px;
  height: 18px;
  // background-image: url(../assets/joindiscord.png);
}

.menus a .item-arrow-hot {
  background-image: url(../assets/bunny/hot.png);
}
.buttons {
  text-align: center;
  padding: 0 52px;
  position: absolute;
  bottom: 30px;
  left: 0;
  width: 100%;
}
.buttons,
.buttons a {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.common-close {
  position: absolute;
  top: 35px;
  width: 20px;
  height: 20px;
  transform: translateY(-50%) rotate(45deg);
  text-indent: -999em;
}

.common-close:after,
.common-close:before {
  content: " ";
  line-height: 2px;
  background: #333;
  position: absolute;
}
.common-close:before {
  height: 2px;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
.common-close:after {
  width: 2px;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
}
.common-close:after,
.common-close:before {
  background: #fff;
}
</style>
