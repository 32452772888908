/*
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2022-05-16 10:30:49
 * @LastEditors: dding dding@ggfun.me
 * @LastEditTime: 2024-08-12 15:36:01
 * @FilePath: /bunnyWebsite/src/locale/cn.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

export default {
    'navbar.home': '主頁',
    'navbar.market': '市場',
    'navbar.game': '游戲',
    'navbar.rabbit': '得寶兔',
    'navbar.bunny': '九堡兔',
    'navbar.team': '路綫圖',
    'navbar.roadMap': '團隊',
    'navbar.docs': '文檔',
    'navbar.about': '關於',

    'home.title': 'COBEWORLD提供基於NFT的\n游戲場景和數據營銷',

    "choose.title": "選擇得寶兔NFT",
    "choose.uniqueId": "唯一ID:",
    "choose.uniqueIdTtitle": "  獨有身份象徵。",
    "choose.collection": "集合:",
    "choose.collectionTitle": "  收藏,理財,升值,分紅。",
    "choose.Pledge": "質押:",
    "choose.PledgeTitle": "  質押,保底3倍返現。",
    "choose.mint": "鍍造:",
    "choose.mintTitle": "  鍍造并合成，以提升其价值。",
    "choose.buy": "立即購買",

    "game.title": "WEB3.0 GAME-FI 遊戲站",
    "game.subtitle": "九堡兔",
    "game.content": "男女老少都能參與的鏈遊!\n通過區塊鏈連接錢包進入鏈遊闖關。\n没有NFT者也可以玩!",
    "game.content1": "每天闖關都會獲得拼圖碎片,5塊碎片可以合成火箭!\n動力火箭可提高算力5%-100%，加速權益的釋放。",
    "game.paly": "開始遊戲",



    "origin.title": "COBEWORLD起源",
    "origin.subtitle": " COBEWORLD的使命，為所有人提供可幸賴\n的Web 3.0平台，提供公平公開的市場。",
    "origin.content": "來自區塊鏈、遊戲、金融，藝術和Web 3.0技術的專家團隊，共同打造COBEWORLD獨有的、高成長性的元宇宙生態系統。",
    "origin.chain": "區塊鏈",
    "origin.game": "遊戲",
    "origin.finance": "金融",
    "origin.art": "藝術",



    "stake.title": "NFT挖礦",
    "stake.subtitle": "通過消耗生命值可採集ZEP生態通證,賦能生態系統",
    "stake.description0": "生命值滿格銷毀NFT轉化為ZEP生態通證",
    "stake.description1": "EP生態通證對應產出會消耗生命值",
    "stake.description2": "ZEP生態通證根據算力持續生產",
    "stake.description3": "生命值耗盡時ZEP生態通證停止產出",



    "pledge.title": "NFT質押",
    "pledge.subtitle": "NFT和ZEP可通過年化儲蓄永久保留，最終獲得財富",
    "pledge.content": "NFT和ZEP可通過質押得到7%-42%年化率率增長",


    "roadmap.title": "路綫圖",
    "roadmap.r1": "",
    "roadmap.r1_info": ["", "", "", ""],
    "roadmap.r1_points": ["團隊組建", "概念和想法的發展", "得寶兔NFT戰略合作夥伴關係", "得寶兔NFT推出", "NFT Game-Fi上線", "卡塔爾世界盃2022X得寶兔", "獲得美國VC“A”輪6百萬美⾦融資"],
    "roadmap.r2": "",
    "roadmap.r2_info": ["", "", "", ""],
    "roadmap.r2_points": ["CEX/DEX上市", "BSC NFT質押", "NFT將在主要市場上市", "鑄幣NFT的進展", "推出3D NFT", "推出3D Game-Fi", "APR質押", "推出NFT系列玩具", "推出Social-Fi"],
    "roadmap.r3": "",
    "roadmap.r3_info": ["", "", "", ""],
    "roadmap.r3_points": ["⽬標每⽇活躍⽤戶超過5萬", "完成教育學院元宇宙", "在港交所上市", "AI NFT 產品", "⾹港交易市場NFT許可證", "NFT產品驗證", "NFT跨鏈科技"],
    "roadmap.r4": "",
    "roadmap.r4_info": ["", "", "", ""],
    "roadmap.r4_points": ["⽬標每⽇活躍⽤戶超過20萬", "新加坡NFT市場許可證", "cobeworld Metaverse世界地圖", "cobeworld X房地產開發", "美國NFT交易市場許可證", "實現元宇宙上班體質", "實現元宇宙⾐⻝住⾏"],





    "team.title": "團隊",
    "team.ceo_title": "CEO(創始人)",
    "team.ceo_name": "Chris何",
    "team.ceo_introduce": "曾任職於騰訊、阿裡、Onchain等國內知名上市公司，曾參與中國權威標準製定，包括工信部電子技術標準研究院牽頭首推《區塊鏈參考架構》、中國信通院可信區塊鏈《供應鏈協同應用白皮書》等。",
    "team.coo_title": "COO(創始人)",
    "team.coo_name": "James陳",
    "team.coo_introduce": "曾任職於騰訊、巨人、Onchain等國內知名上市公司，有著豐富的研發從業經驗，曾參與AR/VR、Instant-ngp三維重建科技、基於遊戲的LBS地圖服務等核心技術的開發。",
    "team.cbo_title": "商務總監",
    "team.cbo_name": "Eric趙",
    "team.cbo_introduce": "曾任職於阿裡、網易、蝸牛等多家公司，有著豐富的從業經驗，先後參與了複星、紅衫等多輪千萬級A輪投資項目。",
    "team.cto_title": "技術總監",
    "team.cto_name": "Carl",
    "team.cto_introduce": "曾任職於百度，螞蟻集團等多家與區塊鏈相關的公司，在web3.0的技術研發領域有著豐富的從業經驗，曾帶領團隊開發了超過50個區塊鏈應用程序。",

    "partner.title": "合作夥伴",

    "docs.next": "下一頁",
    "docs.previous": "上一頁",

    "about.title": "關於",
    "Contact.title": "聯繫我們",
    "brandGuide.title": "品牌指南",
    "navigate.title": "導航",
    "navigate.subtitle0": "1",
    "navigate.subtitle0": "2",
    "navigate.subtitle0": "3",
    "community":"社區",
    "contact.title":"聯繫",
    "contact_content.title":"讓我們聯繫",
    "contact_content.subtitle":"如果你有問題\n想開始提交商業計畫或者簡單\n想要聯繫。\n請隨時給我們發信息\n在右邊聯繫表格中",
    "contact.required":"要求",
    "submit.title":"提交",
    "contactname":"名字 *",
    "contactemail":"郵箱 *",
    "contactcompany":"緣由",
    "contactmessage":"額外信息",
    "success.title":"信息已上傳成功。",
    "fail.title":"信息已上傳失敗,請重試!",
    "warning.title":"表單信息請填寫不全",
    "test":"测试",

    "news.title":"最新消息",
    "news.more":"更多",
}
