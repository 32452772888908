<template>
  <section class="section-item section-economics">
    <div class="normal-layout section-content">
      <div class="subtitle">
        {{ localeData["team.title"] }}
      </div>
    </div>
  </section>
  <section class="section-item section-team" id="section-team">
    <div class="headView" id="section-team"></div>
    <div class="normal-layout section-content">
      <div class="economics-list">
        <div class="cover">
          <div class="roadmaps-bg roadmaps-item">
            <img src="../../assets/bunny/15.png" alt />

            <div class="roadmap-item-title">
              {{ localeData["team.ceo_title"] }}
            </div>

            <div class="roadmap-item-sutitle">
              {{ localeData["team.ceo_name"] }}
            </div>

            <div class="roadmap-item-texts">
              {{ localeData["team.ceo_introduce"] }}
            </div>
          </div>
        </div>

        <div class="cover">
          <div class="roadmaps-bg roadmaps-item">
            <img src="../../assets/bunny/16.png" alt />
            <div class="roadmap-item-title">
              {{ localeData["team.coo_title"] }}
            </div>

            <div class="roadmap-item-sutitle">
              {{ localeData["team.coo_name"] }}
            </div>

            <div class="roadmap-item-texts">
              {{ localeData["team.coo_introduce"] }}
            </div>
          </div>
        </div>

        <div class="cover">
          <div class="roadmaps-bg roadmaps-item">
            <img src="../../assets/bunny/17.png" alt />
            <div class="roadmap-item-title">
              {{ localeData["team.cbo_title"] }}
            </div>

            <div class="roadmap-item-sutitle">
              {{ localeData["team.cbo_name"] }}
            </div>

            <div class="roadmap-item-texts">
              {{ localeData["team.cbo_introduce"] }}
            </div>
          </div>
        </div>

        <div class="cover">
          <div class="roadmaps-bg roadmaps-item">
            <img src="../../assets/bunny/18.png" alt />
            <div class="roadmap-item-title">
              {{ localeData["team.cto_title"] }}
            </div>

            <div class="roadmap-item-sutitle">
              {{ localeData["team.cto_name"] }}
            </div>

            <div class="roadmap-item-texts">
              {{ localeData["team.cto_introduce"] }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="section-item section-supporter">
    <div class="normal-layout section-content">
      <div class="title">{{ localeData["partner.title"] }}</div>
      <div class="friends-list">
        <span href target="_blank" class="friend-item">
          <img src="../../assets/bunny/binance.png" alt="" />
        </span>
        <span href target="_blank" class="friend-item">
          <img src="../../assets/bunny/bitkeep.png" alt="" />
        </span>
        <span href target="_blank" class="friend-item">
          <img src="../../assets/bunny/superrare.png" alt="" />
        </span>
        <span href target="_blank" class="friend-item">
          <img src="../../assets/bunny/looksrare.png" alt="" />
        </span>
        <span href target="_blank" class="friend-item">
          <img src="../../assets/bunny/token.png" alt="" />
        </span>
        <span href target="_blank" class="friend-item">
          <img src="../../assets/bunny/element.png" alt="" />
        </span>
        <span href target="_blank" class="friend-item">
          <img src="../../assets/bunny/market.png" alt="" />
        </span>
        <span href target="_blank" class="friend-item">
          <img src="../../assets/bunny/dynamic.png" alt="" />
        </span>
        <span href target="_blank" class="friend-item">
          <img src="../../assets/bunny/metamask.png" alt="" />
        </span>
        <span href target="_blank" class="friend-item">
          <img src="../../assets/bunny/cointelegraph.png" alt="" />
        </span>
      </div>
    </div>
  </section>
</template>
<script setup>
import useLocale from "@/hooks/useLocale";
const localeData = useLocale();
</script>

<style lang="less" scoped>
@import "./index.less";

@media screen and (min-width: 960px) {
  .headView {
    height: 100px;
    margin: auto;
    margin-top: -100px;
  }
  .section-team {
    .economics {
      padding-top: 60px;
    }
    .title {
      text-align: center;
      padding-top: 60px;
    }
    .economics-list {
      padding: 120px 0 100px;
      display: flex;
      justify-content: center;
    }
    .cover {
      width: 25%;
      img {
        // position: absolute;
        position: relative;
        text-align: center;
        display: block;
        object-fit: cover;
        margin: auto;
      }
    }
    .economics-item {
      flex: 1;
      margin: 15px;
      padding: 48px 40px 50px;
      transition: transform 0.4s ease;
    }

    .studio-item {
      flex: 1;
      margin: 15px;
      padding: 24px 20px 25px;
      transition: transform 0.4s ease;
    }
    .icon-studio {
      height: 0;
      padding-bottom: 50%;
      position: relative;
      img {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        object-fit: contain;
      }
    }

    .icon {
      height: 0;
      padding-bottom: 100%;
      position: relative;
      img {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        object-fit: contain;
      }
    }
    .item-title {
      font-size: 18px;
      font-weight: 600;
      text-align: center;
      margin-top: 30px;
    }

    .roadmaps-bg {
      background: #232323;
      border-radius: 16px;
      border: 1px solid #3c3c3c;
      box-sizing: border-box;
    }

    .roadmaps-item {
      // flex: 1;
      margin: auto;
      // height: 420px;
      margin: 10px;
      padding: 24px 20px 25px;
      transition: transform 0.4s ease;
    }
    .roadmap-item-title {
      margin: auto;
      margin-bottom: 10px;
      font-size: 22px;
      font-weight: 600;
      text-align: center;
      margin-top: 15px;
      color: #fd9e00;
    }
    .roadmap-item-sutitle {
      margin: auto;
      margin-bottom: 10px;
      font-size: 18px;
      font-weight: 600;
      color: #ffffff;
      text-align: center;
    }
    .roadmap-item-texts {
      font-size: 12px;
      font-weight: 600;
      color: #909090;
      text-align: center;
    }
  }
  .section-supporter {
    background-color: #303030;
    .economics {
      padding-top: 60px;
    }
    .title {
      text-align: center;
      padding-top: 60px;
      font-size: 40px;
    }
  }

  .friends-list {
    padding: 60px 0 100px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-left: -10px;
    margin-right: -10px;
    .friend-item {
      width: 250px;
      height: 75px;
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: contain;
      margin: 10px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      &:hover {
        filter: none;
      }
    }
  }

  .section-economics {
    width: 100%;
    height: 120px;
    background-size: contain;
    background-image: linear-gradient(#303030, #1d1d1d);
    .subtitle {
      margin: auto;
      padding-top: 25px;
      margin-bottom: 25px;
      font-size: 40px;
      text-align: center;
    }
  }
}

@media screen and (max-width: 960px) {
  .headView {
    height: 100px;
    margin: auto;
    margin-top: -100px;
  }
  .section-team {
    .economics {
      padding-top: 60px;
    }
    .title {
      text-align: center;
      padding-top: 60px;
    }
    .economics-list {
      padding: 20px 0 30px;
      display: flex;
      flex-flow: column;
      justify-content: center;
    }
    .cover {
      width: 100%;
      img {
        position: relative;
        text-align: center;
        display: block;
        object-fit: cover;
        margin: auto;
      }
    }
    .economics-item {
      flex: 1;
      margin: 15px;
      padding: 48px 40px 50px;
      transition: transform 0.4s ease;
    }

    .studio-item {
      flex: 1;
      margin: 15px;
      padding: 24px 20px 25px;
      transition: transform 0.4s ease;
    }
    .icon-studio {
      height: 0;
      padding-bottom: 50%;
      position: relative;
      img {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        object-fit: contain;
      }
    }

    .icon {
      height: 0;
      padding-bottom: 100%;
      position: relative;
      img {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        object-fit: contain;
      }
    }
    .item-title {
      font-size: 18px;
      font-weight: 600;
      text-align: center;
      margin-top: 30px;
    }

    .roadmaps-bg {
      background: #232323;
      border-radius: 16px;
      border: 1px solid #3c3c3c;
      box-sizing: border-box;
    }

    .roadmaps-item {
      // flex: 1;
      margin: auto;
      // height: 420px;
      margin: 10px;
      padding: 24px 20px 25px;
      transition: transform 0.4s ease;
    }
    .roadmap-item-title {
      margin: auto;
      margin-bottom: 10px;
      font-size: 22px;
      font-weight: 600;
      text-align: center;
      margin-top: 15px;
      color: #fd9e00;
    }
    .roadmap-item-sutitle {
      margin: auto;
      margin-bottom: 10px;
      font-size: 18px;
      font-weight: 600;
      color: #ffffff;
      text-align: center;
    }
    .roadmap-item-texts {
      font-size: 12px;
      font-weight: 600;
      color: #909090;
      text-align: center;
    }
  }
  .section-supporter {
    background-color: #303030;
    .economics {
      padding-top: 10px;
    }
    .title {
      text-align: center;
      padding-top: 30px;
      font-size: 20px;
    }
  }

  .friends-list {
    padding: 20px 0 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
 
    .friend-item {
      width: 44%;
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: contain;
      margin: 10px;
      // filter: grayscale(1);
      // transition: filter 0.4s ease;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      &:hover {
        filter: none;
      }
    }
  }

  .section-economics {
    width: 100%;
    height: 60px;
    background-size: contain;
    background-image: linear-gradient(#303030, #1d1d1d);
    .subtitle {
      margin: auto;
      padding-top: 15px;
      margin-bottom: 15px;
      font-size: 20px;
      text-align: center;
    }
  }
}

</style>