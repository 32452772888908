<template>
	<section class="section-item section-about">
		<div class="coming"></div>
	</section>
</template>

<script setup>
import useLocale from '@/hooks/useLocale'
const localeData=useLocale()
</script>


<style lang="less" scoped>
@import "./index.less";

.section-about{
	display: flex;
	align-items: center;
}

.coming {
	background: url("../../assets/coming.jpg");
	background-repeat:no-repeat;
	background-position:center;
	background-size: 100%;
	
    z-index: 1;
	
	padding: 20px 20px 20px;
    
	width: 100vw;
    height: 80vh;

}

@media screen and (max-width: 960px) {

	.coming {
		padding: 20px;
	    width: 90%;
	}
}
</style>