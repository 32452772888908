<template>
  <section class="section-item section-economics">
    <div class="normal-layout section-content">
      <div class="subtitle">
        {{ localeData["roadmap.title"] }}
      </div>
    </div>
  </section>
  <section class="section-item section-friends">
    <div class="headView" id="section_friends"></div>
    <div class="normal-layout section-content">
      <!-- <div class="title" style="color:#efa22a"></div> -->
      <div class="economics-list">
        <div class="cover">
          <img src="../../assets/bunny/11.png" alt />
          <div class="roadmaps-bg roadmaps-item">
            <div
              v-for="(item, i) in localeData['roadmap.r1_points']"
              class="roadmap-item-texts"
            >
              {{ item }}
            </div>
          </div>
        </div>

        <div class="cover">
          <img src="../../assets/bunny/12.png" alt />
          <div class="roadmaps-bg roadmaps-item">
            <div
              v-for="(item, i) in localeData['roadmap.r2_points']"
              class="roadmap-item-texts"
            >
              {{ item }}
            </div>
          </div>
        </div>

        <div class="cover">
          <img src="../../assets/bunny/13.png" alt />
          <div class="roadmaps-bg roadmaps-item">
            <div
              v-for="(item, i) in localeData['roadmap.r3_points']"
              class="roadmap-item-texts"
            >
              {{ item }}
            </div>
          </div>
        </div>

        <div class="cover">
          <img src="../../assets/bunny/14.png" alt />
          <div class="roadmaps-bg roadmaps-item">
            <div class="roadmap-item-title">{{ localeData["roadmap.r4"] }}</div>
            <div
              v-for="(item, i) in localeData['roadmap.r4_points']"
              class="roadmap-item-texts"
            >
              {{ item }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

</template>


<script setup>
import useLocale from "@/hooks/useLocale";
const localeData = useLocale();
</script>

<style lang="less" scoped>
@import "./index.less";

.ot {
  color: #efa22a;
}
.headView {
  height: 100px;
  margin: auto;
  margin-top: -100px;
}
.section-friends {
  .economics {
    padding-top: 60px;
  }
  .title {
    text-align: center;
    padding-top: 60px;
  }
  .economics-list {
    padding: 120px 0 100px;
    display: flex;
    justify-content: center;
  }
  .cover {
    width: 25%;
    img {
      // position: absolute;
      position: relative;
      text-align: center;
      display: block;
      object-fit: cover;
      margin: auto;
    }
  }
  .economics-item {
    flex: 1;
    margin: 15px;
    padding: 48px 40px 50px;
    transition: transform 0.4s ease;
  }

  .studio-item {
    flex: 1;
    margin: 15px;
    padding: 24px 20px 25px;
    transition: transform 0.4s ease;
  }
  .icon-studio {
    height: 0;
    padding-bottom: 50%;
    position: relative;
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      object-fit: contain;
    }
  }

  .icon {
    height: 0;
    padding-bottom: 100%;
    position: relative;
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      object-fit: contain;
    }
  }
  .item-title {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    margin-top: 30px;
  }

  .roadmaps-bg {
    background: #232323;
    border-radius: 16px;
    border: 1px solid #3c3c3c;
    box-sizing: border-box;
  }

  .roadmaps-item {
    margin: auto;
    // height: 300px;
    margin: 10px;
    padding: 24px 20px 25px;
    transition: transform 0.4s ease;
  }
  .roadmap-item-title {
    font-size: 22px;
    font-weight: 600;
    text-align: left;
    margin-top: 15px;
    color: #fd9e00;
  }
  .roadmap-item-texts {
    margin: auto;
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: 600;
    color: #909090;
  }
}
.section-supporter {
  background-color: #292347;
  .economics {
    padding-top: 60px;
  }
  .title {
    text-align: center;
    padding-top: 60px;
  }
}

.friends-list {
  padding: 60px 0 100px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-left: -10px;
  margin-right: -10px;
  .friend-item {
    width: 250px;
    height: 75px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain;
    margin: 10px;
    // filter: grayscale(1);
    // transition: filter 0.4s ease;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    &:hover {
      filter: none;
    }
  }
}

.section-economics {
  width: 100%;
  height: 120px;
  background-size: contain;
  background-image: linear-gradient(#303030, #1d1d1d);
  .subtitle {
    margin: auto;
    padding-top: 25px;
    margin-bottom: 25px;
    font-size: 40px;
    text-align: center;
  }
}

@media screen and (max-width: 960px) {
  .section-economics {
    width: 100%;
    height: 60px;
    background-size: contain;
    background-image: linear-gradient(#303030, #1d1d1d);
    .subtitle {
      margin: auto;
      padding-top: 15px;
      margin-bottom: 15px;
      font-size: 20px;
      text-align: center;
    }
  }

  .section-friends .economics {
    padding: 60px 20px 0;
  }

  .section-friends .economics-list {
    padding: 25px 0 50px;
    flex-wrap: wrap;
  }

  .section-friends .economics-list .roadmaps-item {
    margin: auto;
    // height: 190px;
    margin: 10px;
    padding: 10px 10px 10px;
    transition: transform 0.4s ease;
  }

  .section-friends .economics-list .cover {
    width: 90%;
    img {
      position: relative;
      text-align: center;
      display: block;
      object-fit: cover;
      margin: auto;
      width: 120px;
    }
    .roadmap-item-texts {
      margin: auto;
      margin-bottom: 2px;
      font-size: 12px;
      font-weight: 600;
      color: #909090;
    }
  }

  .section-friends .economics-list .economics-item {
    width: 260px;
    -webkit-box-flex: unset;
    -ms-flex: unset;
    flex: unset;
    margin: 8px;
  }
  .section-friends .economics-list .studio-item {
    width: 300px;
    -webkit-box-flex: unset;
    -ms-flex: unset;
    flex: unset;
    margin: 8px;
  }

  .section-friends .supporter {
    padding: 0 20px;
  }
  .section-friends .friends-list {
    padding: 25px 0 50px;
  }
  .section-friends .friends-list .friend-item {
    margin: 8px;
  }
  .section-supporter .title {
    text-align: center;
    padding-top: 60px;
  }
  .section-supporter .friends-list {
    padding: 25px 0 50px;
  }
  .section-supporter .friends-list .friend-item {
    margin: 8px;
  }
}
</style>