<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-03-30 14:24:18
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-03-31 16:53:42
 * @FilePath: /bunnyWebsite/src/pages/index/SectionChoose.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <section class="section-item section-economics">
    <div class="normal-layout section-content">
      <div class="subtitle">
        {{ localeData["stake.title"] }}
      </div>
    </div>
  </section>
  <section class="section-item section-stake">
    <div class="normal-layout section-content">
      <div class="rowItem">
        <div class="content-left">
          <div class="imageView">
            <img class="image" src="../../assets/bunny/5.png" />
            <img class="image2" src="../../assets/bunny/8.png" />
            <div class="leftTitlte">{{ localeData["stake.description0"] }}</div>
            <div class="leftTitlte1">
              {{ localeData["stake.description1"] }}
            </div>
            <div class="rightTitlte">
              {{ localeData["stake.description2"] }}
            </div>
            <div class="rightTitlte1">
              {{ localeData["stake.description3"] }}
            </div>
          </div>
        </div>
        <div class="content-right">
          <img class="image" src="../../assets/bunny/9.png" />
          <div class="subtitle">
            {{ localeData["stake.subtitle"] }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import useLocale from "@/hooks/useLocale";
const localeData = useLocale();
</script>
<script>
export default {
  name: "staking",
  data() {
    return {
      isPlaying: false,
    };
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
@import "./index.less";
.section-stake {
  .section-content {
    margin: auto;
    padding-top: 30px;
    margin-bottom: 0px;
    display: flex;
    flex-flow: column;

    .rowItem {
      margin: auto;
      margin-bottom: 30px;
      display: flex;
      flex-flow: row;
      .content-left {
        margin: auto;
        display: flex;
        flex-flow: row;
        width: 50%;
        justify-content: center;
        .imageView {
          position: relative;
          .image {
            top: 0;
            left: 0;
          }
          .image2 {
            position: absolute;
            top: 26%;
            left: 31.5%;
          }
          .leftTitlte {
            margin: auto;
            font-size: 16px;
            text-align: left;
            width: 140px;
            margin-top: -80.5%;
            position: absolute;
            color: #0894a9;
          }
          .leftTitlte1 {
            margin: auto;
            font-size: 16px;
            text-align: left;
            width: 140px;
            margin-top: -80.5%;
            margin-left: 75%;
            position: absolute;
            color: #9140c3;
          }
          .rightTitlte {
            margin: auto;
            position: absolute;
            font-size: 16px;
            text-align: left;
            margin-top: -4.2%;
            width: 140px;
            color: #54b247;
          }
          .rightTitlte1 {
            margin: auto;
            position: absolute;
            font-size: 16px;
            text-align: left;
            margin-left: 75%;
            margin-top: -4.2%;
            width: 140px;
            color: #4141c3;
          }
        }
      }
      .content-right {
        margin: auto;
        margin-top: 30px;
        margin-left: 20px;
        margin-right: 20px;
        width: 50%;
        text-align: center;
        .title {
          margin: auto;
          font-size: 40px;
          text-align: left;
          width: 100%;
        }
        .subtitle {
          margin: auto;
          margin-top: 20px;
          margin-bottom: 20px;
          text-align: center;
          font-size: 20px;
          white-space: pre-line;
          color: #ffffff;
        }
        .buttons {
          margin: auto;
          margin-top: 60px;
          text-align: center;
          font-size: 20px;
          color: #ffffff;
          a {
            display: inline-flex;
            width: 180px;
          }
        }
      }
      .title {
        margin: auto;
        margin-top: 20px;
        margin-bottom: 20px;
        text-align: center;
        font-size: 20px;
        white-space: pre-line;
        color: #ffffff;
      }
    }
  }
}
.section-economics {
  width: 100%;
  height: 120px;
  background-size: contain;
  background-image: linear-gradient(#303030, #1d1d1d);
  .subtitle {
    margin: auto;
    padding-top: 25px;
    margin-bottom: 25px;
    font-size: 40px;
    text-align: center;
  }
}

@media screen and (max-width: 960px) {
  .section-stake {
    .section-content {
      margin: auto;
      padding-top: 30px;
      margin-bottom: 0px;
      display: flex;
      flex-flow: column;

      .rowItem {
        margin: auto;
        margin-bottom: 30px;
        display: flex;
        flex-flow: column;
        .content-left {
          margin: auto;
          display: flex;
          flex-flow: row;
          width: 100%;
          justify-content: center;
          .imageView {
            position: relative;
            .image {
              top: 0;
              left: 0;
            }
            .image2 {
              position: absolute;
              top: 24%;
              left: 30%;
              width: 40%;
            }
            .leftTitlte {
              margin: auto;
              font-size: 14px;
              text-align: left;
              width: 100px;
              margin-top: -82%;
              position: absolute;
              color: #0894a9;
            }
            .leftTitlte1 {
              margin: auto;
              font-size: 14px;
              text-align: right;
              width: 100px;
              margin-top: -82%;
              margin-left: 71%;
              position: absolute;
              color: #9140c3;
            }
            .rightTitlte {
              margin: auto;
              position: absolute;
              font-size: 14px;
              text-align: left;
              width: 100px;
              color: #54b247;
            }
            .rightTitlte1 {
              margin: auto;
              position: absolute;
              font-size: 14px;
              text-align: right;
              margin-left: 71%;
              width: 100px;
              color: #4141c3;
            }
          }
        }
        .content-right {
          margin: auto;
          width: 100%;
          text-align: center;
          .title {
            margin: auto;
            font-size: 16px;
            text-align: left;
            width: 100%;
          }
          .subtitle {
            margin: auto;
            margin-top: 10px;
            margin-bottom: 30px;
            text-align: left;
            font-size: 16px;
            color: #ffffff;
          }
          .image{
            width: 40%;
            margin: auto;
            margin-top: 60px;
          }
       
        }
        .title {
          margin: auto;
          margin-top: 20px;
          text-align: left;
          font-size: 16px;
          color: #ffffff;
        }
      }
    }
  }
  .section-economics {
    width: 100%;
    height: 60px;
    background-size: contain;
    background-image: linear-gradient(#303030, #1d1d1d);
    .subtitle {
      margin: auto;
      padding-top: 15px;
      margin-bottom: 15px;
      font-size: 20px;
      text-align: center;
    }
  }
}

</style>