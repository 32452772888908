<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-03-30 14:24:18
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-04-06 13:45:03
 * @FilePath: /bunnyWebsite/src/pages/index/SectionChoose.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <section class="section-item section-economics">
    <div class="normal-layout section-content">
      <div class="subtitle">
        {{ localeData["game.title"] }}
      </div>
    </div>
  </section>
  <section class="section-item section-game" id="section-game">
    <div class="normal-layout section-content">
      <div class="rowItem">
        <div class="content-right">
          <div class="title">
            {{ localeData["game.subtitle"] }}
          </div>
          <div class="subtitle">
            {{ localeData["game.content"] }}
          </div>
        </div>
        <div class="content-left">
          <img class="imageview" src="../../assets/bunny/3.png" />
          <img class="imageview1" src="../../assets/bunny/4.png" />
        </div>
      </div>
      <div class="rowItem">
        <div class="content-left">
          <!-- <img src="../../assets/home-video.png" /> -->
          <div class="video-content">
            <video
              ref="video0"
              playsinline
              loop="loop"
              poster=""
              controls="controls"
            >
              <source src="../../assets/video.mp4" type="video/mp4" />
            </video>
            <div class="videoBg" @click="do_pause()"></div>
            <div class="video-img" v-show="!isPlaying" @click="do_play()"></div>
          </div>
        </div>
        <div class="content-right">
          <div class="subtitle" style="text-align: center">
            {{ localeData["game.content1"] }}
          </div>
          <div class="buttons">
            <a
              ____target="_blank"
              href="https://game.zeptosfintech.io/"
              class="common-button button-42 button-second"
              >{{ localeData["game.paly"] }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import useLocale from "@/hooks/useLocale";
const localeData = useLocale();
</script>
<script>
export default {
  name: "CommonVideo",
  data() {
    return {
      isPlaying: false,
    };
  },

  mounted() {
    this.$refs.video0.addEventListener("play", () => {
      this.isPlaying = true;
    });
    // 监听视频暂停
    this.$refs.video0.addEventListener("pause", () => {
      this.isPlaying = false;
    });
  },

  methods: {
    do_play() {
      this.$refs.video0.play();
    },
    do_pause() {
      this.$refs.video0.pause();
    },
  },
};
</script>
<style lang="less" scoped>
@import "./index.less";
@media screen and (min-width: 960px) {
  .section-game {
    // background: #000000;
    .section-content {
      margin: auto;
      padding-top: 30px;
      margin-bottom: 60px;
      display: flex;
      flex-flow: column;

      .rowItem {
        margin: auto;
        margin-bottom: 30px;
        display: flex;
        flex-flow: row;
        width: 100%;
        .content-left {
          margin: auto;
          display: flex;
          flex-flow: row;
          width: 100%;
          justify-content: center;
          .video-content {
            background: url("../../assets/video_1.png") no-repeat center;
            width: 364px;
            height: 600px;
            video {
              margin: auto;
              width: 100%;
              height: 100%;
              vertical-align: middle;
            }

            .videoBg {
              position: relative;
              top: -104%;
              left: -15%;
              width: 473px;
              height: 680px;
              z-index: 10;
              text-align: center;
              background: url("../../assets/video_1.png") no-repeat;
              background-size: 100% 100%;
              cursor: pointer;
            }
            .video-img {
              position: relative;
              top: -170%;
              left: 40%;
              width: 60px;
              height: 60px;
              z-index: 10;
              text-align: center;
              background: url("../../assets/video1.png") no-repeat;
              background-size: 100% 100%;
              cursor: pointer;
            }
          }
          .imageview {
            width: 70%;
            margin-right: 70px;
          }
          .imageview1 {
            width: 70%;
          }
        }
        .content-right {
          margin: auto;
          margin-left: 20px;
          margin-right: 20px;
          width: 100%;
          .title {
            margin: auto;
            font-size: 40px;
            text-align: left;
            width: 100%;
          }
          .subtitle {
            margin: auto;
            margin-top: 20px;
            margin-bottom: 20px;
            text-align: left;
            font-size: 20px;
            white-space: pre-line;
            color: #ffffff;
          }
          .buttons {
            margin: auto;
            margin-top: 60px;
            text-align: center;
            font-size: 20px;
            color: #ffffff;
            a {
              display: inline-flex;
              width: 180px;
            }
          }
        }
      }
    }
  }
  .section-economics {
    width: 100%;
    height: 120px;
    background-size: contain;
    background-image: linear-gradient(#303030, #1d1d1d);
    .subtitle {
      margin: auto;
      padding-top: 25px;
      margin-bottom: 25px;
      font-size: 40px;
      text-align: center;
    }
  }
}

@media screen and (max-width: 960px) {
  .section-game {
    .section-content {
      margin: auto;
      padding-top: 30px;
      margin-bottom: 30px;
      display: flex;
      flex-flow: column;

      .rowItem {
        margin: auto;
        margin-bottom: 30px;
        display: flex;
        flex-flow: column;
        width: 100%;
        .content-left {
          margin: auto;
          display: flex;
          flex-flow: row;
          width: 100%;
          justify-content: center;
          .video-content {
            background: url("../../assets/video_1.png") no-repeat center;
            width: 364px;
            height: 600px;
            video {
              margin: auto;
              width: 100%;
              height: 100%;
              vertical-align: middle;
            }

            .videoBg {
              position: relative;
              top: -104%;
              left: -15%;
              width: 473px;
              height: 680px;
              z-index: 10;
              text-align: center;
              background: url("../../assets/video_1.png") no-repeat;
              background-size: 100% 100%;
              cursor: pointer;
            }
            .video-img {
              position: relative;
              top: -170%;
              left: 40%;
              width: 60px;
              height: 60px;
              z-index: 10;
              text-align: center;
              background: url("../../assets/video1.png") no-repeat;
              background-size: 100% 100%;
              cursor: pointer;
            }
          }
          .imageview {
            width: 50%;
            margin: auto;
          }
          .imageview1 {
            width: 50%;
            margin: auto;
          }
        }
        .content-right {
          margin: auto;
          margin-top: 30px;
          width: 100%;
          .title {
            margin: auto;
            font-size: 15px;
            text-align: left;
            width: 100%;
          }
          .subtitle {
            margin: auto;
            margin-top: 10px;
            margin-bottom: 10px;
            text-align: left;
            font-size: 15px;
            white-space: pre-line;
            color: #ffffff;
          }
          .buttons {
            margin: auto;
            margin-top: 30px;
            text-align: center;
            font-size: 20px;
            color: #ffffff;
            a {
              display: inline-flex;
              width: 160px;
            }
          }
        }
      }
    }
  }
  .section-economics {
    width: 100%;
    height: 60px;
    background-size: contain;
    background-image: linear-gradient(#303030, #1d1d1d);
    .subtitle {
      margin: auto;
      padding-top: 15px;
      margin-bottom: 15px;
      font-size: 20px;
      text-align: center;
    }
  }
}
</style>