/*
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2022-05-16 10:30:49
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-08-16 14:35:26
 * @FilePath: /bunnyWebsite/src/main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createApp } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'
import App from './App.vue'
import store from './store'

import tip_View from './components/tipView/index.js'


const Home = { template: '<div>home</div>' }
const Coming = { template: '<div>coming</div>' }
const Docs = { template: '<div>docs</div>' }
const Gather = { template: '<div>gather</div>' }
const News = { template: '<div>News</div>' }
const NewsDetail = { template: '<div>NewsDetail</div>' }

const routes = [
	{ path: '/', component: Home },
	{ path: '/coming', component: Coming },
	{ path: '/docs', component: Docs },
	{ path: '/gather', component: Gather },
	{ path: '/news', component:News},
	{ path: '/newsDetail',component:NewsDetail},
]

const router = createRouter({
	history: createWebHashHistory(),
	routes,
})
router.afterEach((to, from, next) => {
	window,
		scrollTo(0, 0)
})
const app = createApp(App);
app.use(store)
app.use(router)
app.mount('#app');
app.config.globalProperties.$tipView = tip_View;
store.dispatch('initLng')