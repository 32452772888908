<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-03-30 14:24:18
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-03-31 16:55:23
 * @FilePath: /bunnyWebsite/src/pages/index/SectionChoose.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <section class="section-item section-economics">
    <div class="normal-layout section-content">
      <div class="subtitle">
        {{ localeData["pledge.title"] }}
      </div>
    </div>
  </section>
  <section class="section-item section-pledge" id="section-pledge">
    <div class="normal-layout section-content">
      <div class="content-right">
        <img class="image" src="../../assets/bunny/10.png" />
      </div>
      <div class="content-left">
        <div class="subtitle">
          {{ localeData["pledge.subtitle"] }}
        </div>
        <div class="subtitle">
          {{ localeData["pledge.content"] }}
        </div>

      </div>
    </div>
  </section>
</template>

<script setup>
import useLocale from "@/hooks/useLocale";
const localeData = useLocale();
</script>
<script>
export default {
  name: "staking",
  data() {
    return {
      isPlaying: false,
    };
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
@import "./index.less";
.section-pledge {
  .section-content {
    margin: auto;
    padding-top: 80px;
    margin-bottom: 60px;
    display: flex;
    flex-flow: row;
    .content-left {
      margin: auto;
      display: flex;
      flex-flow: column;
      align-content: flex-end;
      width: 100%;
      .subtitle {
        margin: auto;
        margin-bottom: 20px;
        text-align: center;
        font-size: 20px;
        white-space: pre-line;
        color: #ffffff;
      }
    }
    .content-right {
      margin: auto;
      margin-left: 20px;
      margin-right: 20px;
      width: 100%;
      text-align: center;
    }
  }
}
.section-economics {
  width: 100%;
  height: 120px;
  background-size: contain;
  background-image: linear-gradient(#303030, #1d1d1d);
  .subtitle {
    margin: auto;
    padding-top: 25px;
    margin-bottom: 25px;
    font-size: 40px;
    text-align: center;
  }
}

@media screen and (max-width: 960px) {
  .section-pledge {
  .section-content {
    margin: auto;
    padding-top: 30px;
    margin-bottom: 30px;
    display: flex;
    flex-flow: column;
    .content-left {
      margin: auto;
      display: flex;
      flex-flow: column;
      align-content: flex-end;
      width: 100%;
      .subtitle {
        margin: auto;
        // margin-top: 10px;
        text-align: center;
        font-size: 16px;
        white-space: pre-line;
        color: #ffffff;
      }
    }
    .content-right {
      margin: auto;
      margin-bottom: 20px;
      width: 70%;
      text-align: center;
    }
  }
}
.section-economics {
  width: 100%;
  height: 60px;
  background-size: contain;
  background-image: linear-gradient(#303030, #1d1d1d);
  .subtitle {
    margin: auto;
    padding-top: 16px;
    margin-bottom: 16px;
    font-size: 20px;
    text-align: center;
  }
}
}
</style>