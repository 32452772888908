<template>
  <main class="app-container" v-show="!$route.meta.hideHeader">
    <navbar></navbar>
    <div class="home-page">
      <section-coming v-if="path == '/coming'"></section-coming>

      <!-- <section-docs v-if="path == '/docs'"></section-docs> -->
      <section-gather v-if="path == '/gather'"></section-gather>

      <page-news v-if="path == '/news'"></page-news>
      <page-news-detail v-if="path == '/newsDetail'"></page-news-detail>

      <router-view v-if="isShowMain(path)">
        <section-home></section-home>
        <section-choose></section-choose>
        <section-game></section-game>
        <section-origin></section-origin>
        <section-stake></section-stake>
        <section-pledge></section-pledge>
        <section-friends></section-friends>
        <section-team></section-team>
        <section-about></section-about>
      </router-view>
    </div>
  </main>
</template>

<script setup>
import Navbar from "./Navbar.vue";
import SectionComing from "./SectionComing.vue";
import SectionHome from "./SectionHome.vue";
import SectionChoose from "./SectionChoose.vue";
import SectionGame from "./SectionGame.vue";

import SectionOrigin from "./SectionOrigin.vue";
import SectionStake from "./SectionStake.vue";
import SectionPledge from "./SectionPledge.vue";

import SectionFriends from "./SectionFriends.vue";

import SectionTeam from "./SectionTeam.vue";
// import SectionDocs from "./SectionDocs.vue";

import SectionAbout from "./SectionAbout.vue";
import SectionGather from "./SectionGather.vue";

import PageNews from "./PageNews.vue";
import PageNewsDetail from "./PageNewsDetail.vue";


// import Foot from "./Foot.vue";
</script>

<style lang="less" scoped></style>


<script>
export default {
  name: "Index",
  data() {
    return {
      path: this.$route.path,
    };
  },
  watch: {
    $route(to, from) {
      console.log(to.path);
      this.path = to.path;
    },
  },

  mounted() { },

  methods: {

    isShowMain(path) {
      console.log("path ", path);
      if(path == '/coming' || path == '/SectionSkin'
        || path == '/docs' || path == '/gather'
        || path == '/news' || path == '/newsDetail')
        return false;

      return true;
        
        
    }

  },
};
</script>
