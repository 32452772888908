<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-03-30 14:24:18
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-03-31 18:03:20
 * @FilePath: /bunnyWebsite/src/pages/index/SectionChoose.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <section class="section-item section-choose">
    <div class="normal-layout section-content">
      <div class="content-left">
        <img src="../../assets/bunny/2.png" />
      </div>
      <div class="content-right">
        <div class="title">
          {{ localeData["choose.title"] }}
        </div>
        <div class="subtitle">
          {{ localeData["choose.uniqueId"] }}
          <span>{{ localeData["choose.uniqueIdTtitle"] }}</span>
        </div>
        <div class="subtitle">
          {{ localeData["choose.collection"]
          }}<span>{{ localeData["choose.collectionTitle"] }}</span>
        </div>
        <div class="subtitle">
          {{ localeData["choose.Pledge"]
          }}<span>{{ localeData["choose.PledgeTitle"] }}</span>
        </div>
        <div class="subtitle">
          {{ localeData["choose.mint"]
          }}<span>{{ localeData["choose.mintTitle"] }}</span>
        </div>

        <div class="buttons">
          <a
            ____target="_blank"
            href="https://dapp.zeptosfintech.io/buy"
            class="common-button button-42 button-second"
            >{{ localeData["choose.buy"] }}</a
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import useLocale from "@/hooks/useLocale";
const localeData = useLocale();
</script>
<style lang="less" scoped>
@import "./index.less";
.section-choose {
  .section-content {
    margin: auto;
    padding-top: 30px;
    margin-bottom: 60px;
    display: flex;
    flex-flow: row;
    .content-left {
      margin: auto;
      width: 90%;
      margin-left: 20px;
      margin-right: 20px;
    }
    .content-right {
      margin: auto;
      margin-left: 20px;
      margin-right: 20px;
      width: 90%;
      .title {
        margin: auto;
        font-size: 40px;
        text-align: center;
        width: 100%;
      }
      .subtitle {
        margin: auto;

        margin-top: 20px;
        margin-bottom: 20px;
        text-align: center;
        font-size: 20px;
        color: #f9bf32;
        span {
          color: #ffffff;
        }
      }
      .buttons {
        margin: auto;
        margin-top: 60px;
        text-align: center;
        font-size: 20px;
        color: #ffffff;
        a {
          display: inline-flex;
          width: 180px;
        }
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .section-choose {
  .section-content {
    margin: auto;
    padding-top: 30px;
    margin-bottom: 30px;
    display: flex;
    flex-flow: column;
    .content-left {
      margin: auto;
      width: 70%;
      // margin-left: 20px;
      // margin-right: 20px;
      text-align: center;
    }
    .content-right {
      margin: auto;
      width: 90%;
      .title {
        margin: auto;
        font-size: 18px;
        text-align: center;
        width: 100%;
      }
      .subtitle {
        margin: auto;
        margin-top: 10px;
        margin-bottom: 10px;
        text-align: center;
        font-size: 16px;
        color: #f9bf32;
        span {
          color: #ffffff;
        }
      }
      .buttons {
        margin: auto;
        margin-top: 30px;
        text-align: center;
        font-size: 20px;
        color: #ffffff;
        a {
          display: inline-flex;
          width: 160px;
        }
      }
    }
  }
}
}
</style>