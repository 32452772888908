/*
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2022-03-09 16:21:12
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-02-17 13:11:33
 * @FilePath: /bunnyWebsite/src/store/states.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {
    languageList: [
        { value: 'en', label: 'English' },
        { value: 'cn', label: '繁體中文' },
    ],
    curLan: { value: 'en', label: 'English' }
}