/*
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-09-27 15:19:46
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-08-16 17:39:41
 * @FilePath: /dapp_vue/src/common.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import axios from 'axios';
const api = "https://admin.zeptosfintech.io/api/";
export function post(url, params, loading_tip, callback, ext = {}) {
    if (typeof ext.count == "undefined") {
        ext.count = 0;
    }
    ext.count++;
    if (ext.mask) {

    }
    let apiurl = api + url;
    axios.post(apiurl, params)
        .then(res => {
            res = res.data;
            if (res) {
                callback(res);
            } else {
                if (ext.reconnect) {
                    setTimeout(() => {
                        post(url, params, callback, ext)
                    }, 7000 * ext.count);
                } else {
                }
            }
        })
        .catch(function (error) { // 请求失败处理
            if (ext.reconnect) {
                setTimeout(() => {
                    post(url, params, callback, ext)
                }, 7000 * ext.count);
            } else {
            }
        });
}
export function get(url, callback) {
    axios.get(url)
        .then(res => {
            if (res.status == 200) {
                callback(res.data);
            } else {
                callback(false);
            }
        })
        .catch(function (error) { // 请求失败处理
            callback(error);
        });
}

export function getJsonData(url, callback) {
    axios.get(url)
        .then(res => {
            callback(res);
        })
        .catch(function (error) {
        });
}

export function isEmptyStr(s) {
    if (s == undefined || s == null || s == '') {
        return true
    }
    return false
}

