<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2022-05-16 10:30:49
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-08-11 19:24:50
 * @FilePath: /bunnyWebsite/src/App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
	<div id="app">
		<router-view />
		<index/>
	</div>
</template>

<script setup>
import index from '@/pages/index'
import comingsoon from '@/pages/index/comingsoon'
</script>

<style lang="less">
@import "./styles/reset.css";
@import "./styles/flex.css";
@import "./styles/base.less";
@import "./styles/certify.css";
@import "./styles/swiper-bundle.min.css";
</style>

<script>

export default {
	name: "App",
	data() {
		return {
		}
	},

	mounted() {

	},

	methods: {
	}
	
}


</script>
