<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-07-07 17:16:19
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-08-17 13:56:23
 * @FilePath: /bunnyWebsite/src/pages/index/SectionDocs.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <section class="section-item section-gather">
    <div class="normal-layout section-content">
      <div class="leftView">
        <div class="round_image"></div>
        <div class="left_title">{{ localeData["contact_content.title"] }}</div>
        <div class="left_subtitle">
          {{ localeData["contact_content.subtitle"] }}
        </div>
      </div>
      <div class="middle_line"></div>
      <div class="rigthView">
        <div class="right_title">{{ localeData["contact.title"] }}</div>
        <div class="right_subtitle">{{ localeData["contact.required"] }}</div>
        <form name="formData">
          <input
            class="input_title"
            type="name"
            id="name"
            name="name"
            :placeholder="localeData.contactname"
            required
          />
          <input
            class="input_title"
            type="email"
            id="email"
            name="email"
            :placeholder="localeData.contactemail"
            required
          />
          <input
            class="input_title"
            type="company"
            id="company"
            name="company"
            :placeholder="localeData.contactcompany"
            required
          />
          <input
            class="input_title"
            style="height: 100px"
            type="exinfo"
            id="exinfo"
            name="exinfo"
            :placeholder="localeData.contactmessage"
            required
          />
        </form>
        <button class="btn_submit" @click="sendFormData()">
          {{ localeData["submit.title"] }}
        </button>
      </div>
    </div>
  </section>
</template>
<script setup>
import useLocale from "@/hooks/useLocale";
import { post, isEmptyStr } from "@/api/common";
const localeData = useLocale();
</script>
<script>
export default {
  name: "gather",

  data() {
    return {
      data: {
      
      },
    };
  },
  mounted() {
  },

  watch() {},

  methods: {
    sendFormData() {
      var name = document.getElementById("name").value;
      var email = document.getElementById("email").value;
      var company = document.getElementById("company").value;
      var exinfo = document.getElementById("exinfo").value;

      if (isEmptyStr(name) || isEmptyStr(email) || isEmptyStr(company)) {
        this.$tipView({
          message: this.localeData["warning.title"],
        });
      } else {
        var data = {
          name: name,
          email: email,
          data: company,
          exinfo: exinfo,
        };
        post(
          `contact`,
          { data: data },
          this.localeData["contact.name"],
          (res) => {
            if (res.code == 0) {
              this.$tipView({
                message: this.localeData["success.title"],
              });
            } else {
              this.$tipView({
                message: this.localeData["fail.title"],
              });
            }
          },
          { mask: true, reconnect: true }
        );
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";

input::-webkit-input-placeholder {
  color: #969696;
  font-size: 16px;
}
input:-moz-placeholder {
  color: #969696;
  font-size: 16px;
}
input::-moz-placeholder {
  color: #969696;
  font-size: 16px;
}
input:-ms-input-placeholder {
  color: #969696;
  font-size: 16px;
}
@media screen and (min-width: 960px) {
  .section-gather {
    margin: auto;
    display: flex;
    width: 100vw;
    .section-content {
      width: 100vw;
      // height: 80vh;
      padding: 20px;
      display: flex;
      border: 2px solid transparent;
      border-radius: 10px;
      background-clip: padding-box, border-box;
      background-origin: padding-box, border-box;
      background-image: linear-gradient(to right, #000, #000),
        linear-gradient(to right, #ad7755, #aa4b5b);
      // margin: auto;
      // margin-bottom: 20px;

      .leftView {
        width: 50%;
        text-align: center;
        color: white;
        .round_image {
          background: url("../../assets/round.png") no-repeat center;

          background-size: 100% 100%;
          background-repeat: no-repeat;
          width: 280px;
          height: 0;
          padding-bottom: 280px;
          opacity: 0.2;
          margin: auto;
          margin-left: calc((100% - 280px) / 2);
          margin-top: 200px;
        }
        .left_title {
          margin: auto;
          margin-top: -260px;
          margin-bottom: 50px;
          font-size: 30px;
        }

        .left_subtitle {
          font-size: 20px;
          margin: auto;
          margin-left: 10%;
          width: 80%;
          white-space: pre-line;
        }
      }
      .middle_line {
        width: 1px;
        height: 80%;
        margin: auto;
        margin-top: 10%;
        margin-bottom: 10px;
        background-image: linear-gradient(to top, #ad7755, #aa4b5b),
          linear-gradient(to top, #ad7755, #aa4b5b);
      }
      .rigthView {
        width: 50%;
        text-align: center;
        color: white;
        .right_title {
          margin: auto;
          margin-top: 10px;
          margin-bottom: 30px;
          font-size: 40px;
          color: rgba(220, 113, 64, 1);
        }
        .right_subtitle {
          font-size: 18px;
          color: white;
          width: 80%;
          text-align: left;
          margin: auto;
          margin-bottom: 10px;
        }
        .input_title {
          margin: auto;
          margin-bottom: 20px;
          padding: 10px;
          color: white;
          font-size: 16px;
          width: 80%;
          height: 40px;
          border: 2px solid transparent;
          border-radius: 10px;
          background-clip: padding-box, border-box;
          background-origin: padding-box, border-box;
          background-image: linear-gradient(to right, #000, #000),
            linear-gradient(to right, #ad7755, #aa4b5b);
        }

        .btn_submit {
          margin: auto;
          margin-top: 20px;
          width: 60%;
          height: 70px;
          border-radius: 10px;
          font-size: 26px;
          color: white;
          border: 0px solid transparent;
          background-clip: padding-box, border-box;
          background-origin: padding-box, border-box;
          background-image: linear-gradient(to right, #0476d3, #8857ea);
        }
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .section-gather {
    margin: auto;
    display: flex;
    width: 100vw;
    .section-content {
      width: 96%;
      margin: auto;
      margin-left: 2%;
      padding: 20px;
      border: 2px solid transparent;
      border-radius: 10px;
      background-clip: padding-box, border-box;
      background-origin: padding-box, border-box;
      background-image: linear-gradient(to right, #000, #000),
        linear-gradient(to right, #ad7755, #aa4b5b);

      .round_image {
        background: url("../../assets/round.png") no-repeat center;

        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 280px;
        height: 0;
        padding-bottom: 280px;
        opacity: 0.2;
        margin: auto;
        margin-left: calc((100% - 280px) / 2);
        margin-top: 30px;
      }
      .leftView {
        width: 100%;
        text-align: center;
        color: white;
        .left_title {
          margin: auto;
          margin-top: -230px;
          margin-bottom: 10px;
          font-size: 24px;
        }

        .left_subtitle {
          font-size: 16px;
          margin: auto;
          margin-left: 10%;
          width: 80%;
          white-space: pre-line;
        }
      }
      .middle_line {
        width: 98%;
        height: 1px;
        margin: auto;
        margin-top: 100px;
        background-image: linear-gradient(to top, #ad7755, #aa4b5b),
          linear-gradient(to top, #ad7755, #aa4b5b);
      }
      .rigthView {
        width: 100%;
        text-align: center;
        color: white;
        .right_title {
          margin: auto;
          margin-top: 10px;
          margin-bottom: 10px;
          font-size: 24px;
          color: rgba(220, 113, 64, 1);
        }
        .right_subtitle {
          font-size: 16px;
          color: white;
          width: 80%;
          text-align: left;
          margin: auto;
          margin-bottom: 10px;
        }
        .input_title {
          margin: auto;
          margin-bottom: 20px;
          padding: 5px;
          color: white;
          font-size: 16px;
          width: 90%;
          height: 40px;
          border: 2px solid transparent;
          border-radius: 10px;
          background-clip: padding-box, border-box;
          background-origin: padding-box, border-box;
          background-image: linear-gradient(to right, #000, #000),
            linear-gradient(to right, #ad7755, #aa4b5b);
        }

        .btn_submit {
          margin: auto;
          margin-top: 10px;
          width: 60%;
          height: 50px;
          border-radius: 10px;
          font-size: 20px;
          color: white;
          border: 0px solid transparent;
          background-clip: padding-box, border-box;
          background-origin: padding-box, border-box;
          background-image: linear-gradient(to right, #0476d3, #8857ea);
        }
      }
    }
  }
}
</style>