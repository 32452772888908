<!--
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-10-26 20:01:07
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-08-16 14:49:52
 * @FilePath: /dapp_vue/src/components/Dialog/Dialog.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->

<template>
  <div class="tipController" v-if="isShow">
    <div class="view">
      <div class="message_view">
        <!-- <img
          src="@/assets/icon/PI/icon_0.png"
          alt=""
          class="message_view_icon"
        /> -->
        <p class="error_title">{{ text.message }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isShow: true,
      text: {
        message: "",
      },
    };
  },
  mounted() {
    this.show_message();
  },
  methods: {
    show_message() {
      var _this = this;
      _this.isShow = true;
      var messageTimer = setTimeout(() => {
        _this.isShow = false;
        clearTimeout(messageTimer);
      }, 2000);
    },
  },
};
</script>

<style lang="less" scoped>
.tipController {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10000;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  .view {
    position: absolute;
    margin: auto;
    width: 400px;
    top: 24%;
    left: 50%;
    transform: translate(-50%, -50%);
    .message_view {
      margin: auto;
      width: 400px;
      height: 50px;
      background: none;
      display: flex;
      border-radius: 8px;
      border: 1px solid transparent;
      background-clip: padding-box, border-box;
      background-origin: padding-box, border-box;
      background-image: linear-gradient(to right, #ad7755, #aa4b5b);

      .message_view_icon {
        width: 30px;
        height: 30px;
        text-align: center;
      }
      .error_title {
        margin: auto;
        width: 92%;
        height: 30px;
        font-size: 14px;
        font-weight: 700;
        line-height: 30px;
        text-align: center;
        color: #ffffff;
        word-wrap: break-word;
        word-break: normal;
        word-break: break-all;
      }
    }
  }
}
</style>

