<template>
  <section class="section-item page-news">
    <div class="normal-layout section-content">
      <div class="page-title">
        <!-- <> -->
        {{ localeData["news.title"] }}
      </div>

      <div class="news-list">
        <div v-for="(item, index) in news.reverse()" class="new-item" @click="clickNewItem(item)">
          <div class="head">
            <a style="font-size: 18px;color:#ffe283;">{{ item.title }}</a>
            <a style="font-size: 13px;float: right;color:blanchedalmond;position: relative;top: 10px;">{{ item.date }}</a>
          </div>
          <div class="new-content">
            {{ item.des }}
          </div>

        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
import useLocale from "@/hooks/useLocale";
import { post, isEmptyStr } from "@/api/common";
const localeData = useLocale();
</script>
<script>
export default {
  name: "news",
  data() {
    return {
      news: [
        {
          adUrl: require("../../assets/banner/banner3.jpeg"),
          id: 4,
          title: "最新发展",
          des: `很高兴为您提供COBEWORLD Fintech最新进展的消息。随着我们不断发展和扩展我们的平台，我们致力于提高您的体验，为您带来创新功能......`,
          content: `<p>亲爱的COBEWORLD社区成员们，</p><p>&nbsp;</p>
                    <p>很高兴为您提供COBEWORLD&nbsp;Fintech最新进展的消息。随着我们不断发展和扩展我们的平台，我们致力于提高您的体验，为您带来创新功能。</p>
                    <p>&nbsp;</p>
                    <p>以下是COBEWORLD生态系统的关键发展：</p>
                    <p>&nbsp;</p>
                    <ol>
                    <li>COBEWORLD世界：我们的COBEWORLD世界功能已经上线，允许您探索这个激动人心的虚拟世界的各种地点。您可以在不同的地方签到并获得奖励，这个虚拟世界的探索将使您体验到广阔的风景，并享受到它所提供的奖励。</li>
                    </ol>
                    <p>&nbsp;</p>
                    <ol start="2">
                    <li>VIP奖励中心：我们引入了VIP奖励中心，旨在为我们最忠实的用户提供独家福利。作为VIP，您将获得高级功能的使用权限、特殊折扣以及新产品提前体验的机会。这是我们感激您一直以来的支持之道。</li>
                    </ol>
                    <p>&nbsp;</p>
                    <ol start="3">
                    <li>Web 3.0商城：我们的Web 3.0虚拟商城是Zepto生态系统的一项令人激动的新功能。它是一个平台，您可以使用COBEWORLD代币（ZEP）或积分交换产品和服务。这个市场是各种物品的聚集地，我们相信它将为您与COBEWORLD的体验增加新的维度。</li>
                    </ol>
                    <p>&nbsp;</p>
                    <ol start="4">
                    <li>商家签到列表：我们正在扩大商家签到列表。通过访问和签到COBEWORLD世界内的不同地点，您不仅可以享受丰富的体验，还可以解锁由我们合作商家提供的奖励和福利。这种虚拟与现实世界之间的协同作用旨在使您在我们的Web 3.0世界中的旅程变得更加令人激动。</li>
                    </ol>
                    <p>&nbsp;</p>
                    <p>在COBEWORLD&nbsp;Fintech，我们全力致力于创建一个充满活力和活跃的生态系统，以赋予社区更多权能。随着我们不断发展和开发这些新功能，我们将继续把您的需求和体验放在首位。我们渴望看到您如何与这些最新的功能互动，并期待您的反馈。</p>
                    <p>&nbsp;</p>
                    <p>敬请期待更多的更新，不要忘记在我们一起前进的过程中玩耍、赚取并享受Web 3.0世界地图。</p>
                    <p>&nbsp;</p>
                    <p>感谢您一直以来的支持。</p>
                    <p>&nbsp;</p>
                    <p>最诚挚的问候，</p>
                    <p>&nbsp;</p>
                    <p>cobeworld&nbsp;Fintech&nbsp;管理层</p>
                    <p>2023年10月15号</p>`,
          images:[],
          videos:[],
          date: "2023/10/15 12:13",
        },
        {
          adUrl: null,
          id: 5,
          title: "臻品黄师傅&上官岚花",
          des: `广州市海珠区江南西联名店火热新张`,
          content: `广州市海珠区江南西联名店火热新张`,
          images:[],
          videos:[],
          date: "2023/10/16 13:21",
        },
        {
          adUrl: null,
          id: 6,
          title: "喜报！黄师傅荣获中华糕点国际总决赛金奖",
          des: `喜报！黄师傅荣获中华糕点国际总决赛金奖`,
          content: `喜报！黄师傅荣获中华糕点国际总决赛金奖`,
          images:[],
          videos:[],
          date: "2023/11/14 14:52",
        },
        {
          adUrl: null,
          id: 7,
          title: "黄师傅终于正式登陆广州天河区",
          des: `黄师傅终于正式登陆广州天河区`,
          content: `黄师傅终于正式登陆广州天河区`,
          images:[],
          videos:[],
          date: "2023/11/23 15:08",
        },
        // {
        //   adUrl: require("../../assets/banner/bannervideo.mp4"),
        //   id: 3,
        //   title: "Master Huang Opening ceremony",
        //   date: 0,
        //   content: "1111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111",
        //   date:"2023/10/14 12:13",
        // },
        // {

        //   id: 4,
        //   title: "Master Huang Opening ceremony",
        //   date: 0,
        //   content: "1111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111",
        //   date:"2023/10/14 12:13",
        // },
        // {
        //   id: 5,
        //   title: "Master Huang Opening ceremony",
        //   date: 0,
        //   content: "1111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111",
        //   date:"2023/10/14 12:13",
        // },
        // {
        //   id: 6,
        //   title: "Master Huang Opening ceremony",
        //   date: 0,
        //   content: "1111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111",
        //   date:"2023/10/14 12:13",
        // },
        // {
        //   id: 7,
        //   title: "Master Huang Opening ceremony",
        //   date: 0,
        //   content: "1111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111",
        //   date:"2023/10/14 12:13",
        // },
        // {
        //   id: 8,
        //   title: "Master Huang Opening ceremony",
        //   date: 0,
        //   content: "1111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111",
        //   date:"2023/10/14 12:13",
        // },
        // {
        //   id: 9,
        //   title: "Master Huang Opening ceremony",
        //   date: 0,
        //   content: "1111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111",
        //   date:"2023/10/14 12:13",
        // }
      ]
    };
  },
  mounted() {
  },

  watch() { },

  methods: {
    clickNewItem(data) {
      console.log("click new item ", data);
      this.$router.push({
        path: '/newsDetail',
        query: {
          id: data.id
        },
        params: {
          data: data
        }
      })
    }
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";

input::-webkit-input-placeholder {
  color: #969696;
  font-size: 16px;
}

input:-moz-placeholder {
  color: #969696;
  font-size: 16px;
}

input::-moz-placeholder {
  color: #969696;
  font-size: 16px;
}

input:-ms-input-placeholder {
  color: #969696;
  font-size: 16px;
}

.page-title {
  font-size: 40px;
  color: #ffe283;
}

.news-list {
  margin-top: 20px;

}

.new-item {
  margin-bottom: 10px;
  cursor: pointer;
  padding: 10px;

}

.new-item:hover {
  // background-color: #969696;
  opacity: 0.8;
  border-radius: 5px;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;

  // .new-content {
  //   background-color: #ad7755;
  // }
}

.new-content {
  margin-top: 10px;
  border-radius: 5px;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  word-wrap: break-word;
  word-break: break-all;
  background-color: #3534349f;
  padding: 15px;
  // width: 90%;
}

@media screen and (min-width: 960px) {
  .page-news {
    margin: auto;
    display: flex;
    width: 100vw;

    .section-content {
      width: 100vw;
      // height: 80vh;
      padding: 20px;
      // display: flex;
      border: 2px solid transparent;
      border-radius: 10px;
      background-clip: padding-box, border-box;
      background-origin: padding-box, border-box;
      background-image: linear-gradient(to right, #000, #000),
        linear-gradient(to right, #ad7755, #aa4b5b);

      // margin: auto;
      // margin-bottom: 20px;


      .middle_line {
        width: 1px;
        height: 80%;
        margin: auto;
        margin-top: 10%;
        margin-bottom: 10px;
        background-image: linear-gradient(to top, #ad7755, #aa4b5b),
          linear-gradient(to top, #ad7755, #aa4b5b);
      }

    }
  }
}

@media screen and (max-width: 960px) {
  .page-news {
    margin: auto;
    // display: flex;
    width: 100vw;

    .section-content {
      // width: 96%;
      margin: auto;
      margin-left: 2%;
      margin-right: 2%;
      padding: 20px;
      border: 2px solid transparent;
      border-radius: 10px;
      background-clip: padding-box, border-box;
      background-origin: padding-box, border-box;
      background-image: linear-gradient(to right, #000, #000),
        linear-gradient(to right, #ad7755, #aa4b5b);
    }
  }
}
</style>